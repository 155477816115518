import React, { Suspense } from "react";

const ClientPage = React.lazy(() => import("../views/client-dashboard.jsx"));

const Client = () => {
  return (
    <Suspense fallback={null}>
      <ClientPage />
    </Suspense>
  );
};

export default Client;

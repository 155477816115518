import React, { Suspense } from "react";

const Slots = React.lazy(() => import("../views/slots.jsx"));

const Scans = () => {
  return (
    <Suspense fallback={null}>
      <Slots />
    </Suspense>
  );
};

export default Scans;

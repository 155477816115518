import React, { Suspense } from "react";

const DashboardPage = React.lazy(() => import("../views/dashboard.jsx"));

const Dashboard = () => {
  return (
    <Suspense fallback={null}>
      <DashboardPage />
    </Suspense>
  );
};

export default Dashboard;

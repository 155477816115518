import React, { Suspense } from "react";

const LoginPage = React.lazy(() => import("../views/login.jsx"));

const Login = () => {
  return (
    <Suspense fallback={null}>
      <LoginPage />
    </Suspense>
  );
};

export default Login;

import React, { Suspense } from "react";

const AssistedVideoPage = React.lazy(() => import("../views/AssistedVideo.jsx"));

const AssistedVideo = () => {
  return (
    <Suspense fallback={null}>
      <AssistedVideoPage />
    </Suspense>
  );
};

export default AssistedVideo;

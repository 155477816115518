import { createSlice } from "@reduxjs/toolkit";

const AISlice = createSlice({
  name: "Model",
  initialState: {
    filesId: null,
    error: "Error",
    errorShow: false,
  },
  reducers: {
    settingFiles: (state, action) => {
      state.filesId = action.payload;
    },
    setError: (state, action) => {
      if (action.payload.show) {
        state.error = action.payload.error;
        state.errorShow = true;
      } else {
        state.errorShow = false;
      }
    },
  },
});

export const { settingFiles,setError } = AISlice.actions;

export default AISlice.reducer;

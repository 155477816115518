import React from "react";

const Background = ({ content }) => {
  const arr = new Array(40).fill(0);
  return (
    <>
      <div className="w-[100%] h-[100vh] absolute flex overflow-hidden z-10 bg-got">
        {arr.map((item, i) => {
          return (
            <div className="w-[60px] translate-x-[-10px] h-[100vh] py-[10px] flex flex-col shrink-0 justify-between items-center  overflow-hidden">
              {arr.map((item, i) => {
                return (
                  <div className="w-[48px] h-[48px] mb-[10px] backdrop-blur-xl bg-[#ffffff10] rounded-[10px] shrink-0"></div>
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default React.memo(Background);

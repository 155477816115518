import React, { Suspense } from "react";

const AiQuerryyPage = React.lazy(() => import("../views/AiQuerryy.jsx"));

const AiQuerryy = () => {
  return (
    <Suspense fallback={null}>
      <AiQuerryyPage />
    </Suspense>
  );
};

export default AiQuerryy;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./routes/index.jsx";
import Login from "./routes/login.jsx";
import ClientDashboard from "./routes/client-dashboard.jsx";
import ImmersiveExperience from "./routes/immersive-experience.jsx";
import AssistedVideo from "./routes/assisted-video.jsx";
import Dashboard from "./routes/dashboard.jsx";
import Home1 from "./routes/home.jsx";
import AiQuerry from "./routes/ar-querry.jsx";
import Workshop from "./routes/workshop.jsx";
import Scans from "./routes/scans.jsx";
import { Provider } from "react-redux";
import { store } from "./services/store";
import Back from "./utils/back.jsx";
import ApiErrorPrompt from "./utils/apiErrorPrompt";
import WebProjects from "./routes/web-projects.jsx";
import Slots from "./routes/slots.jsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/web-projects",
    element: <WebProjects />,
  },
  {
    path: "/client-dashboard",
    element: <ClientDashboard />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/bg",
    element: <Back />,
  },
  {
    path: "/home",
    element: <Home1 />,
  },
  {
    path: "/ai-querry",
    element: <AiQuerry />,
  },
  {
    path: "/immersive-experience",
    element: <ImmersiveExperience />,
  },
  {
    path: "/assisted-video-learning",
    element: <AssistedVideo />,
  },
  {
    path: "/scans",
    element: <Scans />,
  },
  {
    path: "/workshop",
    element: <Workshop />,
  },
  {
    path: "slots",
    element: <Slots />,
  },
  {
    path: "/slots/irish-luck",
    element: (
      <div className="w-[100%] h-[100vh] absolute z-0 top-0 left-0 ">
        <iframe
          src="https://demos.yudiz.com/html5/irish-luck/"
          frameborder="0"
          className="w-[100vw] h-[100vh]"
        ></iframe>
      </div>
    ),
  },
  {
    path: "/slots/marvellous-mexican",
    element: (
      <div className="w-[100%] h-[100vh] absolute z-0 top-0 left-0 ">
        <iframe
          src="https://demos.yudiz.com/html5/mexican-slot/"
          frameborder="0"
          className="w-[100vw] h-[100vh]"
        ></iframe>
      </div>
    ),
  },
  {
    path: "/slots/neon-classic",
    element: (
      <div className="w-[100%] h-[100vh] absolute z-0 top-0 left-0 ">
        <iframe
          src="https://demos.yudiz.com/html5/neon-slot/"
          frameborder="0"
          className="w-[100vw] h-[100vh]"
        ></iframe>
      </div>
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ApiErrorPrompt />
    <RouterProvider router={router} />
  </Provider>
);

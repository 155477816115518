import React, { Suspense } from "react";

const WorkshopPage = React.lazy(() => import("../views/Workshop-dashboard.jsx"));

const Workshop = () => {
  return (
    <Suspense fallback={null}>
      <WorkshopPage />
    </Suspense>
  );
};

export default Workshop;
